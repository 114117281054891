import React, { useCallback } from 'react';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { Heading1, Paragraph } from '../../components/Typography';
import CheckSVG from '../../images/check-circle.svg';
import Button from '../../components/Button';
import { navigate } from 'gatsby';

function FormSubmitted() {
    return (
        <div>
            <HeroSection />
            <Section
                backgroundColor='grey'
                paddingTop='74px'
                paddingBottom='80px'
            >
                <Container mx='auto'>
                    <Card
                        p={40}
                        maxWidth='540px'
                        mx='auto'
                        justifyContent='center'
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Heading1 textAlign='center'>Form Submitted</Heading1>
                        <img src={CheckSVG} alt='' />
                        <Paragraph textAlign='center'>
                            Your request has been submitted successfully.
                        </Paragraph>
                        <Button
                            height='40px'
                            onClick={useCallback(() => navigate('/'), [])}
                        >
                            Return to homepage
                        </Button>
                    </Card>
                </Container>
            </Section>
        </div>
    );
}

export default FormSubmitted;
